import { Box, Button, Card, CardActionArea, CardActions, CardContent, Dialog, Typography } from '@mui/material'
import React, { useState } from 'react'
import OptionsUploadLablledData from './optionsUploadLablledData'
import OptionsUploadUnlablledData from './optionsUploadUnlablledData'

function UploadDataPage() {

  const [dialog, setDialog] = useState<any>({ status: false, variant: '' })

  const handleDialogClose = () => {
    setDialog({ status: false, variant: '' })
  }
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Card style={{ marginRight: '16px', width: '100%' }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Upload Unlabelled Data
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Description for the unlabelled Data
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions style={{ padding: '16px', paddingTop: '0' }}>
            <Button size="small" color='primary' variant='contained' onClick={() => setDialog({ status: true, variant: 'unlabelled' })}>
              Upload
            </Button>
          </CardActions>
        </Card>
        <Card style={{ marginRight: '16px', width: '100%' }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Upload Labelled Data
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Description for the labelled Data
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions style={{ padding: '16px', paddingTop: '0' }}>
            <Button size="small" color='primary' variant='contained' onClick={() => setDialog({ status: true, variant: 'labelled' })}>
              Upload
            </Button>
          </CardActions>
        </Card>
      </div>

      <Dialog open={dialog.status} onClose={handleDialogClose} fullWidth={true}>
        <Box sx={{ pl: 2, pb: 2, pr: 2, display: 'inline-grid' }}>
          {/* <h4>Change Default Settings</h4>
          <div style={{ display: 'inline-grid', marginTop: '10px' }}>
            just A test
          </div> */}
          {dialog.status && dialog.variant === 'labelled' && <OptionsUploadLablledData />}
          {dialog.status && dialog.variant === 'unlabelled' && <OptionsUploadUnlablledData />}

        </Box>
      </Dialog>
    </>
  )
}

export default UploadDataPage