import services from "..";
import {
  IDataLabellingSelection,
  IActiveSelection,
  IAutoLabelling,
  IDiverseSelection,
  IEvaluation,
  ITargetSelection,
  ITrainingModel,
  ISaveAnnotation,
  IExportAnnotations,
  IPerformInference,
  IExportProjectFiles,
} from "./types";
import { dashboardApiEntryPoint } from "@services/apiEndpoints";
import {
  showErrorSnackbar,
  showSuccessSnacbar,
} from "@components/shared-layouts/Snackbar/helper";
import { saveAs } from "file-saver";
import axios from "axios";
  
const getFinalProjectType = (tempProjectTypePath: string) => {
  if(tempProjectTypePath === 'image_classification_-_(clarifier)') {
    tempProjectTypePath = 'image_classification'
  }
  
  if(tempProjectTypePath === 'object_detection_-_(clarifier)') {
    tempProjectTypePath = 'object_detection'
  }
  
  if(tempProjectTypePath === 'instance_segmentation_-_(clarifier)') {
    tempProjectTypePath = 'instance_segmentation'
  }
  
  return tempProjectTypePath
}

const apiUrlPaths = {
  performDiverseSelection: `/operations/diverse_selection`,
  performAugmentation: `/operations/augmentation`,
  cutPasteAugmentation: `/operations/augmentation/cut-paste`,
  getActiveSelectionHistory: `/operations/active_selection/logs`,
  getTargetSelectionHistory: `/operations/target_selection/logs`,
};

const getApiUrl = (projecttype: string, apiName: string) => {
  const finalUpdateProjectType = getFinalProjectType(projecttype)
  if(apiName === 'saveAnnotations') return `/${finalUpdateProjectType}/data/annotations`
  if(apiName === 'performTraining') return `/${finalUpdateProjectType}/operations/model_training`
  if(apiName === 'performAutoLabelling') return `/${finalUpdateProjectType}/operations/auto_labelling`
  if(apiName === 'performGroundEvaluation') return `/${finalUpdateProjectType}/operations/ground_evaluation`
  if(apiName === 'performAutoEvaluation') return `/${finalUpdateProjectType}/operations/auto_evaluation`
  if(apiName === 'performActiveSelection') return `/${finalUpdateProjectType}/operations/active_selection`
  if(apiName === 'performTargetSelection') return `/${finalUpdateProjectType}/operations/target_selection`
  if(apiName === 'exportProjectFiles') return `/${finalUpdateProjectType}/projects/export_data`
  if(apiName === 'startNewClarifierRound') return `/${finalUpdateProjectType}/operations/clarifier`
  return '/notFound'
}

console.log("Api url path", apiUrlPaths);

// Diverse Selection Apis
export const performDiverseSelection = async (data: IDiverseSelection) => {
  const { project_id, src_dataset, ...settings } = data;

  const formattedData = {
    project_id,
    src_dataset,
    settings: {
      ...settings,
    },
  };

  try {
    const res = await services.post(apiUrlPaths.performDiverseSelection,formattedData);
    return res;
  } catch (error) {
    throw error;
  }
};

// Augmentation Apis
export const performAugmentation = async (data: any) => {
  // First We will check what kind of AUGMENTATION THE USER WANTS TO PERFORM
  const { project_id, augmentation } = data;
  console.log("augmentation type ====>", augmentation);

  // WE CAN MODIFY THE DATA OVER HERE AND CONVERT IT TO FORM DATA

  if (augmentation === "augmentation") {
    const {
      src_dataset,
      num_in_images,
      num_out_images,
      min_img_sizee,
      max_img_sizee,
      pixel_transforms,
      crop_blurs,
      geometric_transforms,
    } = data;

    const augSubmitData = {
      project_id,
      src_dataset,
      num_in_images: Number(num_in_images),
      num_out_images: Number(num_out_images),
      img_size: [min_img_sizee, max_img_sizee],
      pixel_transforms,
      crop_blurs,
      geometric_transforms,
    };

    console.log(augSubmitData);

    try {
      const res = await services.post(apiUrlPaths.performAugmentation, augSubmitData);
      return res;
    } catch (error) {
      throw error;
    }
  } else if (augmentation === "copyPasteAugmentation") {
    const {
      bg_file,
      fg_file,
      class_name,
      min_img_size,
      max_img_size,
      min_obj_range,
      max_obj_range,
      min_x_range,
      max_x_range,
      min_y_range,
      max_y_range,
      obj_width,
      obj_height,
    } = data;

    const copyPasteAugSubmitValues: any = {
      project_id,
      class_name,
      img_size: [min_img_size, max_img_size],
      object_range: [min_obj_range, max_obj_range],
      x_range: [min_x_range, max_x_range],
      y_range: [min_y_range, max_y_range],
      obj_width,
      obj_height,
    };

    let newData = new FormData();

    newData.append("bg_file", bg_file[0]);
    newData.append("fg_file", fg_file[0]);
    newData.append("parameters", JSON.stringify(copyPasteAugSubmitValues));
    // newData.append('force_write', 'true');

    console.log("copypageaugsubmitValues", copyPasteAugSubmitValues);

    console.log("new data ===>", newData);

    try {
      const addHeaders = { "Content-Type": "multipart/form-data" };
      const res = services.post(
        apiUrlPaths.cutPasteAugmentation,
        newData,
        undefined,
        null,
        addHeaders
      );
      return res;
    } catch (error) {
      throw error;
    }
  }
};

// AutoLabelling Apis
export const performAutoLabelling = async (data: IAutoLabelling) => {
  try {
    const apiUrlPath = getApiUrl(data.project_type, 'performAutoLabelling')
    const res = await services.post(apiUrlPath, data);
    return res;
  } catch (error) {
    throw error;
  }
};

// Training Apis <start>
export const performTraining = async (data: ITrainingModel) => {
  const { project_id, ...rest } = data;
  const trainingData = {
    project_id,
    settings: {
      ...rest,
    },
  };

  const apiUrlPath = getApiUrl(data.project_type, 'performTraining')
  try {
    const res = await services.post(apiUrlPath, trainingData);
    return res;
  } catch (error) {
    throw error;
  }
};

// Evaluate Apis
export const performEvaluation = async (data: IEvaluation) => {
  console.log("data which is coming in", data);
  // delete data?.evaluation
  try {
    const url =
      data.evaluation === "groundTruthEvaluation"
        ? getApiUrl(data.project_type, 'performGroundEvaluation')
        : getApiUrl(data.project_type, 'performAutoEvaluation')
    const tempData: IEvaluation = { ...data };
    delete tempData?.evaluation;
    if (data.evaluation === "groundTruthEvaluation") {
      delete tempData?.wandb_aux_run_id;
      delete tempData?.src_dataset;
    }
    const res = await services.post(url, tempData);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getEvaluationLogs = async (projectId: string | number) => {
  try {
    const res: any = await services.get(
      `/operations/evaluation/logs/${projectId}`
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

// Active Selection Apis
export const performActiveSelection = async (data: IActiveSelection) => {
  try {
    const res = await services.post(getApiUrl(data.project_type,'performActiveSelection'), data)
    return res;
  } catch (error) {
    throw error;
  }
};


export const getActiveSelectionHistory = async (
  project_id: number | string
) => {
  try {
    const res: any = await services.get(`${apiUrlPaths.getActiveSelectionHistory}/${project_id}`);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

// Target Selection Apis
export const performTargetSelection = async (data: ITargetSelection) => {
  try {
    const res = await services.post(getApiUrl(data.project_type,'performTargetSelection'), data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getTargetSelectionHistory = async (
  project_id: number | string
) => {
  try {
    const res: any = await services.get(
      `${apiUrlPaths.getTargetSelectionHistory}/${project_id}`
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

// Data labelling APIs
export const performDataLabellingSelection = async (
  data: IDataLabellingSelection
) => {
  try {
    const res = await services.get(
      dashboardApiEntryPoint(data.project_id, data.src_dataset)
        .getUnLabelSetInfoByPId
    );
    return res;
  } catch (error: any) {
    showErrorSnackbar(error?.error?.message ?? "Api could not be called");
    throw error;
  }
};

export const getLabelSetInfoByPId = async (project_id: number | string) => {
  try {
    const res = await services.get(
      dashboardApiEntryPoint(project_id).getLabelSetInfoByPId
    );
    return res;
  } catch (error) {
    throw error;
  }
};

// export const getImageInfoByName = async (project_id: number | string, image_name: string) => {
//   try {
//     const res = await services.get(dashboardApiEntryPoint(project_id).getImageInfoByName + image_name)
//     return res;
//   } catch (error) {
//     throw (error);
//   }
// }

export const getImageInfoByName = async (
  project_id: number | string,
  image_name: string,
  height: string,
  width: string
) => {
  try {
    const res = await services.get(
      dashboardApiEntryPoint(project_id).getImageInfoByName +
        image_name +
        `?height=${height}&width=${width}`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const saveAnnotations = async (data: ISaveAnnotation) => {
  try {
    // Over here we will have to make the change
    const res: any = await services.post(getApiUrl(data.project_type as string, 'saveAnnotations'),data);
    showSuccessSnacbar(res.data?.message);
    return res;
  } catch (e: any) {
    showErrorSnackbar(e?.error?.message ?? "Api could not be called");
    throw e;
  }
};

export const exportAnnotations = async (data: IExportAnnotations) => {
  try {
    const res: any = await services.post(
      dashboardApiEntryPoint().exportAnnotations,
      data
    );
    showSuccessSnacbar(res.data?.message);
    return res;
  } catch (e: any) {
    showErrorSnackbar(e?.error?.message ?? "Api could not be called");
    throw e;
  }
};

// Inference Page API

export const performInference = async (data: IPerformInference) => {
  try {
    console.log("Perform inference data ====>", data);

    // data_source means the file
    // data_set means any of the dataset from dropdown
    const { data_source, src_dataset, project_id, wandb_id, link } = data;

    let formPayload: any = new FormData();

    if (link !== "" && link !== undefined) {
      formPayload.append("data_source", link);
    }

    if (src_dataset !== "" && src_dataset !== undefined) {
      formPayload.append("data_source", src_dataset);
    }

    if (data_source.length > 0) {
      formPayload.append("data_source", data_source[0]);
    }

    formPayload.append("project_id", `${project_id}`);
    formPayload.append("wandb_id", wandb_id);

    let addHeaders = {
      "Content-Type": "multipart/form-data",
    };

    const res = await services.post(
      "/operations/inference",
      formPayload,
      undefined,
      null,
      addHeaders
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getInferenceLogs = async (project_id: number | string) => {
  try {
    const res: any = await services.get(
      `/operations/inference/logs/${project_id}`
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const getInferenceDownloads = async (
  project_id: number | string,
  download_id: number | string
) => {
  try {
    const res: any = await services.get(
      `/operations/inference/logs/${project_id}/${download_id}`
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteInferenceDownloads = async (
  project_id: number | string,
  download_id: number | string
) => {
  try {
    const res: any = await services.delete(
      `/operations/inference/logs/${project_id}/${download_id}`
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteInferenceFileFromGallery = async (
  project_id: number | string,
  download_id: number | string,
  fileName: string
) => {
  try {
    const res: any = await services.delete(
      `/operations/inference/logs/${project_id}/${download_id}/${fileName}`
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
};

// Export API
export const exportProjectFiles = async (data: IExportProjectFiles) => {
  // Over here we have to handle the export
  const { src_dataset, export_format } = data;

  let newExportFormat: string | null = export_format;
  if (
    src_dataset === "unlabelled_set" ||
    src_dataset === "diverse_selected_set" ||
    src_dataset === "active_selected_set" ||
    src_dataset === "target_selected_set"
  ) {
    newExportFormat = null;
  }
  try {
    const res = await services.post(getApiUrl(data.project_type, 'exportProjectFiles'), {
      ...data,
      export_format: newExportFormat,
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const downloadExportedProjectFiles = async (
  project_id: string,
  process_id: string | number,
  token: string
) => {
  try {
    console.log("project id processs, token", project_id, process_id, token);
    const BASE_URL = process.env.REACT_APP_BASE_API;
    const fileUrl = `${BASE_URL}/projects/export_data/downloads/${project_id}/${process_id}`;

    axios
      .get(fileUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob", // Set the response type to blob for images
      })
      .then((response) => {
        const imageUrlObject = URL.createObjectURL(response.data);
        // setSelectedImageIdData(imageUrlObject);
        console.log("axios is running and has got the data");
        saveAs(imageUrlObject, process_id.toString());
      })
      .catch((error) => {
        console.log(error);
      });

    console.log("calling the download export api ");
  } catch (error) {
    throw error;
  }
};

// Dashboard Options API

export const getProjectLogs = async (projectId: string | number) => {
  try {
    const res: any = await services.get(`/projects/logs/${projectId}`);
    return res?.data;
  } catch (e: any) {
    throw e;
  }
};

export const getLogFile = async (logId: string) => {
  try {
    const res: any = await services.get(`/projects/logs/get_log/${logId}`);
    return res?.data;
  } catch (e: any) {
    throw e;
  }
};

// Clarifier Api's

export const getClarifierSettings = async (projectId: string) => {
  try {
    const res: any = await services.get(`/operations/clarifier/settings/${projectId}`);
    return res?.data;
  } catch (e: any) {
    throw e;
  }
};

export const getClarifierLogs = async (projectId: string) => {
  try {
    const res: any = await services.get(`/operations/clarifier/logs/${projectId}`);
    return res?.data;
  } catch (e: any) {
    throw e;
  }
};

export const startNewClarifierRound = async (data: ITargetSelection) => {
  try {    
    const res = await services.post(getApiUrl(data.project_type, 'startNewClarifierRound'), data);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getClarifierBudget = async (projectId: string, scheduler: string, num_rounds: any) => {
  try {
    const res: any = await services.get(`/operations/clarifier/budget_scheduler/${projectId}?scheduler=${scheduler}&num_rounds=${num_rounds}`);
    return res?.data;
  } catch (e: any) {
    throw e;
  }
};