/* eslint-disable react-hooks/exhaustive-deps */
import "../styles.css";
import { useEffect, useState, useContext, useRef } from "react";
import {
  IRIA,
  IRect,
  IExportAnnotations,
} from "@services/dashboardService/types";
import Annotation from "react-image-annotation";
import {
  Paper,
  Grid,
  Pagination,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  IconButton,
  Dialog,
  TextField,
  Chip,
  Button,
} from "@mui/material";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import placeholderImg from "@images/placeholder.png";
import { CustomActionButton } from "@components/shared-ui-components/Buttons";
import ProjectContext from "@context/Project/projectContext";
import {
  ria_to_annotation,
  annotation_to_ria,
  CustomImagePlaceholder,
  RenderTemplate,
} from "../helpers";
import {
  getImageInfoByName,
  saveAnnotations,
  exportAnnotations,
} from "@services/dashboardService";
import { getLocalUrlFromBase64 } from "@utils/imageHelper";
import { ISaveAnnotation } from "@services/dashboardService/types";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { getLabelsV2 } from "@services/projectService";
import {
  showErrorSnackbar,
  showSuccessSnacbar,
} from "@components/shared-layouts/Snackbar/helper";
import "./ObjectDetectionDataLabellingPage.scss";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

const { v4: uuidv4 } = require("uuid");

interface IDataLabellingState {
  annotations: any[];
  annotation: any;
  activeAnnotations: any[];
  editedAnnotations?: any; // This is used to track the edited annotation
}

const initialState: IDataLabellingState = {
  annotations: [],
  annotation: {},
  activeAnnotations: [],
};

const HighlightBx = ({
  children,
  geometry,
  style,
  activeStatus,
  shortcutKeypressCallback,
  annotation,
}: any) => {
  const handleKeyPress = (e: any) => {
    if (e.key) {
      if (activeStatus) {
        shortcutKeypressCallback(e.key, annotation);
      }
    }
  };

  useEffect(() => {
    if (activeStatus) {
      document.addEventListener("keypress", handleKeyPress);
      document.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [activeStatus]);

  return (
    <div
      style={{
        ...style,
        position: "absolute",
        left: `${geometry.x}%`,
        top: `${geometry.y}%`,
        height: `${geometry.height}%`,
        width: `${geometry.width}%`,
      }}
    >
      {children}
    </div>
  );
};

function ObjectDetectionDataLabellingPage() {
  const annotationRef = useRef<HTMLDivElement>(null);
  const projectContextInfo = useContext(ProjectContext);
  const { state: projectState } = projectContextInfo;
  const labels = projectState.labels ?? [];
  const imageIds = projectState.imageIds ?? [];

  const [dataLabellingState, setDataLabellingState] =
    useState<IDataLabellingState>(initialState);
  const [pageNo, setPageNo] = useState(0);
  const [goToPageNo, setGoToPageNo] = useState<any>();
  const [base64Image, setBase64Image] = useState("");
  const [oImageSize, setOImageSize] = useState<IRect>({ height: 0, width: 0 }); // original image size
  const [cImageSize, setCImageSize] = useState<IRect>({ height: 0, width: 0 }); // current image size
  const [bboxes, setBboxes] = useState([]);
  const [class_ids, setClass_ids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [evalRatio, setEvalRatio] = useState<any>();
  const [evalRatioDialog, setEvalRatioDialog] = useState<boolean>(false);
  const [formattedLabelsDataFromV2Api, setFormattedLabelsDataFromV2Api] =
    useState<any>();
  const [officialImageSize, setOfficialImageSize] = useState<any>();
  const [showLabelsAndShortcuts, setShowLabelsAndShortcuts] =
    useState<boolean>(false);

  const [searchParams] = useSearchParams();
  
  const {project_type} = useParams()

  const isFullScreen = searchParams.get("fullScreen");

  const minimumImageHeight = "400";
  const minimumImageWidth = "600";

  // const element: any = document.querySelector('img[alt="annotation image"]');

  const currentImgWidth =
    window.innerWidth - 100 < Number(minimumImageWidth)
      ? minimumImageWidth
      : window.innerWidth - 100;
  const currentImgHeight =
    window.innerHeight - 140 < Number(minimumImageHeight)
      ? minimumImageHeight
      : window.innerHeight - 140;

  console.log(
    "This is the window heigth and width",
    window.innerWidth,
    window.innerHeight,
    currentImgHeight,
    currentImgWidth,
    window.innerHeight - 200
  );

  const navigate = useNavigate();

  const setOriginalImageSizeFromUrl = (url: string) => {
    const imgElement = document.createElement("img");
    imgElement.src = url;

    imgElement.onload = () => {
      // Read the image metadata
      const { width, height } = imgElement;
      console.log("original image size", height, width);
      setOImageSize({ width, height });
    };
  };

  const setCurrentImageSize = () => {
    const element: any = document.querySelector('img[alt="annotation image"]');
    if (element) {
      const width = element.width;
      const height = element.height;
      setCImageSize({ width, height });
    }
  };

  const updateAnnotationsAfterUseEffectRun = (riaList: any) => {
    let _cacheList: any = [];

    if (
      dataLabellingState.editedAnnotations &&
      dataLabellingState.editedAnnotations.length !== 0
    ) {
      // Will check if this same pageNo data annotations is there in the state
      const statusOfPageNoData = dataLabellingState.editedAnnotations.find(
        (x: any) => x.pageNo === pageNo
      );
      _cacheList = statusOfPageNoData ? statusOfPageNoData.mainAnnotations : [];
    }

    setDataLabellingState({
      ...initialState,
      annotations: _cacheList.length > 0 ? _cacheList : riaList,
      editedAnnotations: dataLabellingState.editedAnnotations,
    });
  };

  useEffect(() => {
    getLabelsV2(projectState.projectId)
      .then((resp) => {
        const formattedApiData: any = [];

        const rawApiData = resp.labels;

        for (let i = 0; i < rawApiData.classes?.length; i++) {
          const newObj = {
            classes: rawApiData.classes[i],
            keys: rawApiData?.keys?.[i] ?? null,
            colors: rawApiData?.colors?.[i] ?? null,
          };
          formattedApiData.push(newObj);
        }

        setFormattedLabelsDataFromV2Api(formattedApiData);
      })
      .catch((e) => console.log(e));
  }, []);

  // This is when we are changing the page no
  useEffect(() => {
    async function fetchData() {
      clearAll();
      try {
        setCurrentImageSize();
        setLoading(true);
        let riaList: IRIA[] = []; // RIA mean React Image Annotation
        const res: any = await getImageInfoByName(
          projectState.projectId,
          imageIds[pageNo],
          currentImgHeight.toString(),
          currentImgWidth.toString()
        );

        const _base64Image = res?.data?.img_base64;
        const localUrl: string = await getLocalUrlFromBase64(
          "data:image/png;base64," + _base64Image
        );
        console.log("localurl", localUrl);

        setBase64Image(localUrl);
        setOfficialImageSize({
          width: res?.data?.size[0],
          height: res?.data?.size[1],
        });
        setOriginalImageSizeFromUrl(localUrl);

        riaList = annotation_to_ria(
          res.data.bboxes,
          res.data.class_ids,
          oImageSize,
          labels
        );
        setLoading(false);
        setBboxes(res.data.bboxes);
        setClass_ids(res.data.class_ids);
        updateAnnotationsAfterUseEffectRun(riaList);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }

    if (imageIds && imageIds.length > pageNo) {
      fetchData();
    }
  }, [pageNo]);

  useEffect(() => {
    let riaList: IRIA[] = [];
    riaList = annotation_to_ria(bboxes, class_ids, oImageSize, labels);
    updateAnnotationsAfterUseEffectRun(riaList);
    setLoading(false);
  }, [oImageSize]);

  console.log("dataLabellingState ===>", dataLabellingState);

  const getUpdatedEditedAnnotationsInState = (_annotations: any) => {
    // Example i am getting page no 0 and its in teh edited state
    // First we are checking if the edit annotations  in state has this pageNo data
    let pageNoAnnotationsInState = dataLabellingState?.editedAnnotations?.find(
      (x: any) => x.pageNo === pageNo
    );

    if (pageNoAnnotationsInState) {
      // Means we directly have it we just need to update
      const updatedEditedAnnotations =
        dataLabellingState?.editedAnnotations.map((x: any) => {
          if (x.pageNo === pageNo) {
            return {
              pageNo: x.pageNo,
              image_id: x.image_id,
              oImageSize,
              officialImageSize,
              mainAnnotations: _annotations,
            };
          } else {
            return x;
          }
        });
      return [...updatedEditedAnnotations];
    } else {
      // Means we  have to directly put it
      const _newEditedAnnotations = {
        pageNo,
        image_id: imageIds[pageNo],
        oImageSize,
        officialImageSize,
        mainAnnotations: _annotations,
      };
      if (!dataLabellingState.editedAnnotations) {
        return [_newEditedAnnotations];
      } else {
        return [...dataLabellingState.editedAnnotations, _newEditedAnnotations];
      }
    }
  };

  const handleLabelChange = (selText: string, annotation: IRIA) => {
    const selId = annotation.data?.id;
    // const selText = ev.target.value;

    // !!SelId means if we are creating a new label for the first time or editing it
    // If true means we are editing the label
    // If false means we are assinging a new label

    if (!!selId) {
      const _annotations = dataLabellingState.annotations?.map(
        (annot: IRIA) => {
          if (annot.data.id === selId) {
            return {
              ...annot,
              data: {
                ...annot.data,
                text: selText,
              },
            };
          } else {
            return annot;
          }
        }
      );

      // we are handling the edit for statemanagement
      setDataLabellingState({
        ...dataLabellingState,
        annotations: _annotations,
        editedAnnotations: getUpdatedEditedAnnotationsInState(_annotations),
      });
    } else {
      const { geometry, data } = annotation;
      const newAnnotation = {
        geometry,
        data: {
          ...data,
          text: selText,
          id: uuidv4(),
        },
      };

      const allNewAnnotations =
        dataLabellingState.annotations.concat(newAnnotation);

      setDataLabellingState({
        annotation: {},
        annotations: allNewAnnotations,
        activeAnnotations: dataLabellingState.activeAnnotations,
        editedAnnotations:
          getUpdatedEditedAnnotationsInState(allNewAnnotations),
      });
    }
  };

  // ============= custom render components ==============
  function renderContent(props: any) {
    const annotation = props.annotation;
    return (
      <RenderTemplate geometry={props.annotation.geometry}>
        {renderLabelSelector(annotation)}
        <IconButton onClick={() => handleDelete(annotation.data.id)}>
          <DeleteIcon />
        </IconButton>
      </RenderTemplate>
    );
  }

  const CustomRenderLabelSelectorContent = (props: any) => {
    const handleKeypress = (e: any) => {
      shortcutKeypressCallback(e.key, props.annotation);
    };

    useEffect(() => {
      if (!props.annotation.data) {
        document.addEventListener("keypress", handleKeypress);
      }

      return () => {
        document.removeEventListener("keypress", handleKeypress);
      };
    }, []);

    return (
      <FormControl
        sx={{
          m: 1,
          minWidth: 120,
          padding: 0,
          margin: 0,
        }}
        size="small"
      >
        <InputLabel id="demo-select-small-label" sx={{ fontSize: "12px" }}>
          Labels
        </InputLabel>
        <Select
          size="small"
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={props.annotation?.data?.text ?? ""}
          label="Labels"
          SelectDisplayProps={{ style: { fontSize: "12px" } }}
          onChange={(e) => handleLabelChange(e.target.value, props.annotation)}
        >
          <MenuItem sx={{ fontSize: "12px" }} value="">
            <em>None</em>
          </MenuItem>
          {labels.map((el, index) => {
            return (
              <MenuItem key={index} sx={{ fontSize: "12px" }} value={el}>
                {" "}
                {el}{" "}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const renderLabelSelector = (annotation: IRIA) => {
    return <CustomRenderLabelSelectorContent annotation={annotation} />;
  };

  const renderEditor = (props: any) => {
    const { geometry } = props.annotation;
    if (!geometry) return <></>;

    return (
      <RenderTemplate geometry={props.annotation.geometry}>
        {renderLabelSelector(props.annotation)}
      </RenderTemplate>
    );
  };

  const renderOverlay = () => {
    return <></>;
  };

  const shortcutKeypressCallback = (key: string, annotation: any) => {
    console.log("Key was pressed", key, annotation);

    // Handle the Delete Key press or Backspace
    if (key === "Backspace" || key === "Delete") {
      handleDelete(annotation.data.id);
    } else {
      // Now we will check if the shortcut key is valid
      const indexOfShortcut = formattedLabelsDataFromV2Api.findIndex(
        (obj: any) => obj.keys === key
      );
      // console.log('Index of shortcut', indexOfShortcut)

      if (indexOfShortcut !== -1) {
        //Means there is a short cut key
        console.log(
          "shortcut has been found ",
          formattedLabelsDataFromV2Api[indexOfShortcut]
        );
        // Now we can change the value of the label

        // HERE THERRE WILL BE THE LOGIC OF IT below
        handleLabelChange(
          formattedLabelsDataFromV2Api[indexOfShortcut].classes,
          annotation
        );
        showSuccessSnacbar(
          `Annotation has been marked as ${formattedLabelsDataFromV2Api[indexOfShortcut].classes}`
        );
      } else {
        showErrorSnackbar(`No shortcut key found with letter ( ${key} )`);
      }
    }
  };

  function renderHighlight({ annotation, active }: any) {
    const { geometry, data } = annotation;
    if (!geometry) return null;

    const currentAnnotationText = data.text;

    const indexOfAnnotation = formattedLabelsDataFromV2Api?.findIndex(
      (obj: any) => obj.classes === currentAnnotationText
    );

    const currentLabelColor =
      indexOfAnnotation !== undefined
        ? formattedLabelsDataFromV2Api[indexOfAnnotation]?.colors
        : "red";

    return (
      <HighlightBx
        key={annotation.data.id}
        geometry={geometry}
        style={{
          border: active
            ? `solid 5px ${currentLabelColor || "red"}`
            : `solid 3px ${currentLabelColor || "red"}`,
          borderRadius: active && "10px",
        }}
        activeStatus={active}
        shortcutKeypressCallback={shortcutKeypressCallback}
        annotation={annotation}
      />
    );
  }

  const onChange = (annotation: any) => {
    setDataLabellingState({
      ...dataLabellingState,
      annotation,
    });
  };

  const onSubmit = (annotation: any) => {
    const { geometry, data } = annotation;

    setDataLabellingState({
      annotation: {},
      annotations: dataLabellingState.annotations.concat({
        geometry,
        data: {
          ...data,
          id: uuidv4(),
        },
      }),
      activeAnnotations: dataLabellingState.activeAnnotations,
    });
  };

  const activeAnnotationComparator = (a: any, b: any) => {
    return a.data.id === b;
  };

  const findIndexFromID = (id: string) => {
    if (
      !dataLabellingState.annotations ||
      dataLabellingState?.annotations.length === 0
    )
      return -1;
    let ind = -1;
    const _annot = dataLabellingState.annotations.find(
      (el) => el.data.id === id
    );
    ind = dataLabellingState.annotations.indexOf(_annot);
    return ind;
  };

  const handleDelete = (id: string) => {
    let index = findIndexFromID(id);

    if (index !== -1) {
      const annotationsAfterDelete = [
        ...dataLabellingState.annotations.slice(0, index),
        ...dataLabellingState.annotations.slice(index + 1),
      ];

      setDataLabellingState({
        ...dataLabellingState,
        annotations: annotationsAfterDelete,
        editedAnnotations: getUpdatedEditedAnnotationsInState(
          annotationsAfterDelete
        ),
      });
    }
  };

  const handleImageChange = (page: number) => {
    setPageNo(page - 1);
    setGoToPageNo(page);
    return;
  };

  const handleSave = async () => {
    let _newAnnot: any = [];

    // We have to make the changes in the data passed to ria to annotation
    dataLabellingState.editedAnnotations?.forEach((element: any) => {
      const annot = ria_to_annotation(
        element.mainAnnotations,
        labels,
        element.oImageSize,
        element.image_id,
        element.officialImageSize
      );
      _newAnnot = [..._newAnnot, annot];
    });

    const _data: ISaveAnnotation = {
      project_id: projectState.projectId,
      annotations: _newAnnot,
      project_type
    };

    console.log("final data for save ==>", _data);

    try {
      await saveAnnotations(_data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleExport = async () => {
    setEvalRatioDialog(false);
    // Over here we need to ask for the eval_ratio

    // Open a modal

    // if the evalation ratio is not set then open the dialog
    if (!evalRatio) {
      setEvalRatioDialog(true);
    } else {
      // if the evaluation dialog is set then directly proceed to submitting the form
      const _data: IExportAnnotations = {
        project_id: projectState.projectId,
        src_dataset: projectState?.src_dataset ?? "unlabelled_set",
        export_only_labelled: true,
        eval_ratio: evalRatio,
      };
      try {
        await exportAnnotations(_data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const clearAll = () => {
    // Do not change the edited Annotations state for persistent state management
    setDataLabellingState({
      ...initialState,
      editedAnnotations: dataLabellingState.editedAnnotations,
    });
  };

  return (
    <Paper
      elevation={3} // Add shadow effect
      sx={{
        padding: "10px",
        paddingBottom: "0px",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div id="annotation-image-header">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "12px",
          }}
        >
          <h4 style={{ marginTop: "0px", marginBottom: "0px" }}>
            File Name - {imageIds && imageIds[pageNo]}
          </h4>
          <div>
            <Button
              onClick={() => setShowLabelsAndShortcuts(!showLabelsAndShortcuts)}
              variant={showLabelsAndShortcuts ? "contained" : "outlined"}
              color={showLabelsAndShortcuts ? "secondary" : "primary"}
              style={{ marginRight: "12px" }}
            >
              Show Labels & Shortcuts
            </Button>
            <Button
              startIcon={
                isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />
              }
              onClick={() => {
                const projectType = localStorage
                  .getItem("projectType")
                  ?.toLowerCase()
                  .replace(/ /g, "_");
                isFullScreen
                  ? navigate(
                      `/${projectType}/operations/${projectState.projectId}/data-labelling/selection`
                    )
                  : navigate(
                      `/${projectType}/operations/${projectState.projectId}/data-labelling/selection?fullScreen=true`
                    );
              }}
              variant="contained"
              color="secondary"
            >
              {isFullScreen ? "Minimize" : "Full Screen"}
            </Button>
          </div>
        </div>

        {showLabelsAndShortcuts && formattedLabelsDataFromV2Api && (
          <div
            style={{ display: "flex", marginBottom: "12px", flexWrap: "wrap" }}
          >
            {formattedLabelsDataFromV2Api.map((x: any) => (
              <Chip
                key={x.classes}
                label={`${x.classes} - ( ${x.keys} )`}
                style={{
                  backgroundColor: x.colors,
                  fontWeight: "900",
                  fontSize: "14px",
                  marginRight: "8px",
                  marginTop: "12px",
                }}
                className="label-tag"
              />
            ))}
          </div>
        )}
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ReactPlaceholder
            ready={!loading}
            customPlaceholder={<CustomImagePlaceholder size={cImageSize} />}
          >
            <Annotation
              ref={annotationRef}
              src={base64Image !== "" ? base64Image : placeholderImg}
              alt="annotation image"
              id="img_container"
              annotations={dataLabellingState.annotations}
              value={dataLabellingState.annotation}
              onChange={onChange}
              onSubmit={onSubmit}
              style={{
                width: "100%",
                maxWidht: "100%",
                height: currentImgHeight,
                maxHeight: currentImgHeight,
                // objectFit: 'contain',
                // margin: 'auto'
              }}
              activeAnnotationComparator={activeAnnotationComparator}
              activeAnnotations={dataLabellingState.activeAnnotations}
              renderEditor={renderEditor}
              renderOverlay={renderOverlay}
              renderContent={renderContent}
              renderHighlight={renderHighlight}
              renderSelector={({ annotation }: any) => (
                <HighlightBx
                  geometry={annotation.geometry}
                  style={{
                    border: "3px solid yellow",
                    boxShadow: "0 0 20px 20px rgba(255, 255, 255, 0.3) inset",
                  }}
                  annotation={annotation}
                ></HighlightBx>
              )}
            />
          </ReactPlaceholder>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "12px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={5}
              md={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <CustomActionButton
                btnName="Save"
                isSubmitting={false}
                btnAction={handleSave}
              />
              <CustomActionButton
                btnName="Export"
                isSubmitting={false}
                btnAction={handleExport}
                style={{ marginLeft: "10px" }}
              />
            </Grid>

            <div style={{ display: "flex" }}>
              <Pagination
                // sx={{ margin: 'auto' }}
                count={imageIds.length ?? 0}
                showFirstButton
                showLastButton
                onChange={(e, page) => handleImageChange(page)}
                page={pageNo + 1}
              />
              <TextField
                type="number"
                label="Go to Page"
                variant="outlined"
                size="small"
                // value={pageNo + 1}
                value={goToPageNo}
                defaultValue={pageNo + 1}
                style={{ width: "100px" }}
                // onChange={(e) => { Number(e.target.value) <= imageIds.length && handleImageChange(e, Number(e.target.value)) }}
                onChange={(e) => {
                  setGoToPageNo(Number(e.target.value));
                }}
                inputProps={{ min: 0, max: imageIds.length ?? 0 }}
              />
              <Button
                onClick={() => handleImageChange(goToPageNo)}
                variant="outlined"
                style={{ marginLeft: "12px" }}
              >
                Go To page No {goToPageNo}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>

      <Dialog
        open={evalRatioDialog}
        fullWidth={true}
        onClose={() => setEvalRatioDialog(false)}
      >
        <Box sx={{ p: 2, display: "inline-grid" }}>
          <h4>Please set the Evalution Ratio</h4>
          <TextField
            sx={{ mb: 2 }}
            type="number"
            name="eval_ratio"
            placeholder="Ratio"
            value={evalRatio}
            onChange={(e) => setEvalRatio(e.target.value)}
            InputProps={{
              inputProps: { min: 0, max: 1, step: 0.05 },
            }}
          />
        </Box>
        <CustomActionButton
          btnName="Proceed"
          isSubmitting={false}
          btnAction={handleExport}
        />
      </Dialog>
    </Paper>
  );
}

export default ObjectDetectionDataLabellingPage;
