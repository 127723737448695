import { showErrorSnackbar, showSuccessSnacbar } from '@components/shared-layouts/Snackbar/helper'
import { CustomSubmitButton } from '@components/shared-ui-components/Buttons'
import { CustomNumberField, CustomSwitchField, CustomTextField } from '@components/shared-ui-components/CustomFields'
import CustomAutoLbStrategy from '@components/shared-ui-components/CustomFields/Clarifier/ClarifierPage/CustomAutoLbStrategy'
import CustomClarifierStrategyField from '@components/shared-ui-components/CustomFields/Clarifier/ClarifierPage/CustomClarifierStrategyField'
import OperationSuccessfullyCompletedModal from '@components/shared-ui-components/DynamicForm/OperationSuccessfullyCompletedModal'
import ProgressbarModal from '@components/shared-ui-components/DynamicForm/ProgressbarModal'
import { getDynamicFormValidationSchema } from '@components/shared-ui-components/DynamicForm/helper'
import ProjectContext from '@context/Project/projectContext'
import { Box, Button, Dialog, Paper } from '@mui/material'
import { getClarifierBudget, getClarifierSettings, startNewClarifierRound } from '@services/dashboardService'
import { getProjectType } from '@utils/index'
import { FormikHelpers, useFormik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const initialDefaultValues = {
  base_model: '',
  advance_settings: false,
  hard_strategy: undefined,
  medium_strategy: undefined,
  easy_strategy: undefined,
  auto_lb_strategy: 'High Confidence',
  adaptive: false,
  scheduler: 'Linear', // NA
  num_rounds: 3, // NA
  hard_budget: 0,
  medium_budget: 0,
  easy_budget: 0,
  force_start: false   
}

function ClarifierPage() {
  
  const page = 'clarifier'
  
  const { projectId, project_type } = useParams();
  
  const projectType = getProjectType()
  
  const [initialValues, setInitialValues] = useState(initialDefaultValues);
  
  const [dialogOpenStatus, setDialogOpenStatus] = useState<boolean>(false)
  
  const [operationSuccessModal, setOperationSuccessModal] = useState<boolean>(false);

  const [checkLiveStatusModal, setCheckLiveStatusModal] = useState<boolean>(false);
  
  const projectContextInfo = useContext(ProjectContext);
  const { state: projectState, setState: setProjectState } = projectContextInfo;
  
  
  const handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    
    console.log('handle submit is running')
    
    actions.resetForm();
    
    // Handle form submission
    delete values['scheduler']
    delete values['num_rounds']
    delete values['advance_settings']
    console.log("submit Values ====>", values);
    values["project_id"] = projectId;
    values['force_start'] = values.force_start ?? false
    values['project_type'] = project_type
    // Over here we will get api function to be call
    startNewClarifierRound(values)
      .then((x: any) => {
        console.log("Response from api", x);
        // Reset the form after success
        actions.resetForm();
        // Enable the form
        actions.setSubmitting(false);
        // Set ProjectContext 
        setProjectState({
          ...projectState,
          processId: x.process_id,
        });
        // show a notification progress has been save
        showSuccessSnacbar(x.message);
        // Show Success Modal
        setOperationSuccessModal(true);
      })
      .catch((e: any) => {
        // Over here we need to handle the status codes ===>
        console.log("THis is the rror coming", e);
        // showErrorSnackbar
        if (e.status === 400) {
          showErrorSnackbar(e.error.message);
        }
        actions.setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true, 
    validationSchema: getDynamicFormValidationSchema(page, projectType), // We just need to handle the logic for the validation schema passed here  **** VERY VERY IMPORTANT *****
    onSubmit: handleSubmit,
  });

  const {
    handleSubmit: handleSub,
    isSubmitting,
    errors,
    values,
    touched,
    handleChange,
    setFieldValue
  } = formik || {};
  
  const fetchBudgetScheduler = () => {
    getClarifierBudget(projectId as string, values.scheduler, values.num_rounds)
    .then((resp:any) => {
      const budgetSetting = resp.settings
      setFieldValue('hard_budget', budgetSetting.hard_budget)
      setFieldValue('medium_budget', budgetSetting.medium_budget)
      setFieldValue('easy_budgeti', budgetSetting.easy_budget)
      showSuccessSnacbar('Adaptive Values have been updated')
    })
    .catch(err => {
      console.log(err)
      showErrorSnackbar(err.error.message)
    })
  }
  
  const handleDialogClose = (callFromButton: boolean) => {
    console.log('callfrom button', callFromButton)
    if(callFromButton) fetchBudgetScheduler()
    setDialogOpenStatus(false);
  };
  
  const handleAdaptiveChange = (e:any) => {
   handleChange(e)
   console.log('eeee', e.target.value)

   
   if(e.target.value === 'false') setDialogOpenStatus(true)
  }

  const handleOperationModalClose = () => {
    setOperationSuccessModal(false);
  };

  const handleCheckLiveStatusModalClose = () => {
    setCheckLiveStatusModal(false);
  };

  const handleCheckLiveStatus = () => {
    // First we close the operation successful Modal
    setOperationSuccessModal(false);
    setCheckLiveStatusModal(true);
  };

  
  useEffect(() => {
    
    const fetchSettings = () => {
      console.log('Fetching the clarifier settings')
      getClarifierSettings(projectId as string)
      .then(async(resp) => {
        setInitialValues({...initialValues, ...resp.settings})
      })
      .catch(err => console.log('err', err))
      
    }
    
    fetchSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])

  
  console.log('valuessss----', values, errors)
  
  return (
    <Paper
      elevation={3} // Add shadow effect
      sx={{
        padding: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h4>Please fill up the Clarifier form</h4>
     <form onSubmit={handleSub} style={{ display: "inline-grid" }}>
        
        <CustomTextField
          label={'Base Model'}
          name={'base_model'}
          value={values['base_model']}
          onChange={handleChange}
          error={Boolean(touched['base_model'] && errors['base_model'])}
          sx={{ mb: 2 }}
          disabled={isSubmitting}
        />
        
        <CustomSwitchField
          label={'Advance Settings'}
          name={'advance_settings'}
          value={values['advance_settings']}
          onChange={handleChange}
          sx={{ mb: 2 }}
          disabled={isSubmitting}
        />
        
        {values.advance_settings && <>
          <CustomClarifierStrategyField
            label={'Hard Strategy'}
            name={'hard_strategy'}
            value={values.hard_strategy}
            onChange={handleChange}
            error={Boolean(touched['hard_strategy'] && errors['hard_strategy'])}
            sx={{ mb: 2 }}
            disabled={isSubmitting}
            formik={formik}
          />
          
          <CustomClarifierStrategyField
            label={'Medium Strategy'}
            name={'medium_strategy'}
            value={values['medium_strategy']}
            onChange={handleChange}
            error={Boolean(touched['medium_strategy'] && errors['medium_strategy'])}
            sx={{ mb: 2 }}
            disabled={isSubmitting}
            formik={formik}
          />
          
          <CustomClarifierStrategyField
            label={'Easy Strategy'}
            name={'easy_strategy'}
            value={values['easy_strategy']}
            onChange={handleChange}
            error={Boolean(touched['easy_strategy'] && errors['easy_strategy'])}
            sx={{ mb: 2 }}
            disabled={isSubmitting}
            formik={formik}
          />
          
          <CustomAutoLbStrategy
            label={'Auto LB Strategy'}
            name={'auto_lb_strategy'}
            value={values['auto_lb_strategy']}
            onChange={handleChange}
            error={Boolean(touched['auto_lb_strategy'] && errors['auto_lb_strategy'])}
            sx={{ mb: 2 }}
            disabled={isSubmitting}
            formik={formik}
          />
        </>
        }
        
        <CustomSwitchField
          label={'Adaptive'}
          name={'adaptive'}
          value={values['adaptive']}
          onChange={(e: any) => handleAdaptiveChange(e)}
          error={Boolean(touched['adaptive'] && errors['adaptive'])}
          sx={{ mb: 2 }}
          disabled={isSubmitting}
        /> 

        <CustomNumberField
          label={'Hard Budget'}
          name={'hard_budget'}
          value={values['hard_budget']}
          onChange={handleChange}
          error={Boolean(touched['hard_budget'] && errors['hard_budget'])}
          sx={{ mb: 2 }}
          InputProps={{ inputProps: { min: 0} }}
          disabled={isSubmitting}
        />
        
        <CustomNumberField
          label={'Medium Budget'}
          name={'medium_budget'}
          value={values['medium_budget']}
          onChange={handleChange}
          error={Boolean(touched['medium_budget'] && errors['medium_budget'])}
          sx={{ mb: 2 }}
          InputProps={{ inputProps: { min: 0} }}
          disabled={isSubmitting}
        />
        
        <CustomNumberField
          label={'Easy Budget'}
          name={'easy_budget'}
          value={values['easy_budget']}
          onChange={handleChange}
          error={Boolean(touched['easy_budget'] && errors['easy_budget'])}
          sx={{ mb: 2 }}
          InputProps={{ inputProps: { min: 0} }}
          disabled={isSubmitting}
        />
        
        <CustomSwitchField
          label={'Force Start'}
          name={'force_start'}
          value={values['force_start']}
          onChange={handleChange}
          error={Boolean(touched['force_start'] && errors['force_start'])}
          sx={{ mb: 2 }}
          disabled={isSubmitting}
        /> 
        
        <CustomSubmitButton btnName="Submit" isSubmitting={isSubmitting} />
      
      </form>
      

      <Dialog
        open={dialogOpenStatus}
        onClose={handleDialogClose}
        fullWidth={true}
      >
        <Box sx={{ p: 2, display: "inline-grid" }}>
          <h4>Change Default Settings</h4>
          <div style={{ display: "inline-grid", marginTop: "10px" }}>
            <CustomTextField
              label={'Scheduler'}
              name={'scheduler'}
              value={values['scheduler']}
              onChange={handleChange}
              error={Boolean(touched['scheduler'] && errors['scheduler'])}
              sx={{ mb: 2 }}
              disabled={isSubmitting}
            />
            
            <CustomNumberField
              label={'Number Of Rounds'}
              name={'num_rounds'}
              value={values['num_rounds']}
              onChange={handleChange}
              error={Boolean(touched['num_rounds'] && errors['num_rounds'])}
              sx={{ mb: 2 }}
              InputProps={{ inputProps: { min: 0} }}
              disabled={isSubmitting}
            />
          </div>
          <Button
            variant="contained"
            onClick={() => handleDialogClose(true)}
            color="primary"
            style={{ maxWidth: "100px" }}
          >
            Save
          </Button>
        </Box>
      </Dialog>
      
      
      
      {/* Dialog for showing the Operation Successful Modal */}
      <OperationSuccessfullyCompletedModal
          open={operationSuccessModal}
          close={handleOperationModalClose}
          handleCheckLiveStatus={handleCheckLiveStatus}
        />
        {/* Dialog for Showing the Check Live status  */}
        {checkLiveStatusModal && (
          <ProgressbarModal
            page={page}
            open={checkLiveStatusModal}
            close={handleCheckLiveStatusModalClose}
          />
        )}
      
      
    </Paper>
  )
}

export default ClarifierPage