import DynamicForm from '@components/shared-ui-components/DynamicForm'
import { Paper } from '@mui/material'

function DataLabellingSelectionPage() {

  const handleFieldChange = (fieldName: string, fieldValue: any) => {
    // if (fieldName === 'default_settings') {
    //   if (fieldValue !== false) setDialogOpenStatus(true)
    // }
  }

  return (

    <Paper
      elevation={3} // Add shadow effect
      sx={{
        padding: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h4>Please fill up the data labelling form</h4>
      <DynamicForm page={'dataLabellingSelection'} handleFieldChange={handleFieldChange} />
    </Paper>
  )
}

export default DataLabellingSelectionPage