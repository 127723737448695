
import DynamicForm from '@components/shared-ui-components/DynamicForm'
import { Paper } from '@mui/material'
import React from 'react'

function AugmentationPage() {

  return (
    <Paper
      elevation={3} // Add shadow effect
      sx={{
        padding: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h4>Please fill up the Augmentation form</h4>
      <DynamicForm page={'augmentation'} />
    </Paper>
  )
}

export default React.memo(AugmentationPage)