import DynamicForm from "@components/shared-ui-components/DynamicForm";
import { Paper } from "@mui/material";
import { useLocation } from "react-router-dom";

function DiverseSelectionPage() {
  const handleFieldChange = (fieldName: string, fieldValue: any) => {
    // if (fieldName === 'default_settings') {
    //   if (fieldValue !== false) setDialogOpenStatus(true)
    // }
  };
  
  const currentPage = useLocation()
    .pathname.split("/")[4]
    .replace(/-/g, " ")
    .replace(/\b\w/g, (match) => match.toUpperCase());

  return (
    <Paper
      elevation={3} // Add shadow effect
      sx={{
        padding: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h4>{`Please fill up the ${currentPage} form`}</h4>
      <DynamicForm
        page={"diverseSelection"}
        handleFieldChange={handleFieldChange}
      />
    </Paper>
  );
}

export default DiverseSelectionPage;
