import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Collapse from "@mui/material/Collapse";

import "./sidebar.scss";
// import { makeStyles } from '@mui/material';
import { makeStyles } from "@mui/styles";
import ProjectContext from "@context/Project/projectContext";

const useStyles: any = makeStyles({
  root: {
    "&$selected": {
      backgroundImage: "linear-gradient(to right, #ed8623 , #ed8623a1)",
      borderBottomRightRadius: "25px",
      borderTopRightRadius: "25px",
      "&:hover": {
        // backgroundColor: "tomato"
      },
    },
  },
  selected: {},
});

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
} 



export default function Sidebar(props: Props) {
  
  const projectContextInfo = React.useContext(ProjectContext);
  const { state: projectState } = projectContextInfo;

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const navigate = useNavigate();

  const { projectId, project_type } = useParams();
  
  console.log('project_type', project_type)
  
  let objectDetectionOptions = [
    { name: "Data Labelling", value: "data-labelling" },
    {
      name: 'Diverse Selection',
      value: 'diverse-selection' 
    },
    { name: "Augmentation", value: "augmentation" },
    { name: "Auto Labelling", value: "auto-labelling" },
    { name: "Training", value: "training" },
    {
      name: "Evaluation",
      value: "evaluation",
      children: [
        { name: "Home", value: "evaluation" },
        { name: "Logs", value: "logs" },
      ],
    },
    {
      name: "Tiered Hardness",
      value: "clarifier",
      children: [
        { name: "Start New Round", value: "clarifier" },
        { name: "History", value: "history" },
      ],
    },
    {
      name: "Active Selection",
      value: "active-selection",
      children: [
        { name: "Home", value: "active-selection" },
        { name: "History", value: "history" },
      ],
    },
    {
      name: "Target Selection",
      value: "target-selection",
      children: [
        { name: "Home", value: "target-selection" },
        { name: "History", value: "history" },
      ],
    },
    {
      name: "Inference",
      value: "inference",
      children: [
        { name: "Perform Inference", value: "inference" },
        { name: "Run History", value: "logs" },
      ],
    },
    { name: "Export", value: "export" },
  ];
  
  let imageClassificationOptions = [
    { name: "Data Labelling", value: "data-labelling" },
    { 
      name: 'Diverse Selection',
      value: 'diverse-selection' 
    },
    { name: "Augmentation", value: "augmentation" },
    { name: "Auto Labelling", value: "auto-labelling" },
    {
      name: "Tiered Hardness",
      value: "clarifier",
      children: [
        { name: "Start New Round", value: "clarifier" },
        { name: "History", value: "history" },
      ],
    },
    {
      name: "Active Selection",
      value: "active-selection",
      children: [
        { name: "Home", value: "active-selection" },
        { name: "History", value: "history" },
      ],
    },
    { name: "Training", value: "training" },
    {
      name: "Evaluation",
      value: "evaluation",
      children: [
        { name: "Home", value: "evaluation" },
        { name: "Logs", value: "logs" },
      ],
    },
    { name: "Export", value: "export" },
  ];
  
  
  // let instanceSegmentationClarifierOptions = [
  //   { name: "Data Labelling", value: "data-labelling" },
  //   { 
  //     name: 'Diverse Selection',
  //     value: 'diverse-selection' 
  //   },
  //   { name: "Augmentation", value: "augmentation" },
  //   { name: "Auto Labelling", value: "auto-labelling" },
  //   {
  //     name: "Tiered Hardness",
  //     value: "clarifier",
  //     children: [
  //       { name: "Start New Round", value: "clarifier" },
  //       { name: "History", value: "history" },
  //     ],
  //   },
  //   {
  //     name: "Active Selection",
  //     value: "active-selection",
  //     children: [
  //       { name: "Home", value: "active-selection" },
  //       { name: "History", value: "history" },
  //     ],
  //   },
  //   { name: "Training", value: "training" },
  //   {
  //     name: "Evaluation",
  //     value: "evaluation",
  //     children: [
  //       { name: "Home", value: "evaluation" },
  //       { name: "Logs", value: "logs" },
  //     ],
  //   },
  //   { name: "Export", value: "export" },
  // ];
  
  
  if(project_type === 'image_classification_-_(clarifier)' || project_type === 'object_detection_-_(clarifier)' || project_type === 'instance_segmentation_-_(clarifier)') {
    objectDetectionOptions[1].name = 'Initial Selection'
    objectDetectionOptions[1].value = 'initial-selection'
    imageClassificationOptions[1].name = 'Initial Selection'
    imageClassificationOptions[1].value = 'initial-selection'
    imageClassificationOptions = imageClassificationOptions.filter(x => x.value !== 'active-selection') // We want to remove the active selection
    
  }
  
  // Remove the clarifier option from the side bar for object detection and image classification
  if(project_type === 'object_detection' || project_type === 'image_classification') {
    objectDetectionOptions = objectDetectionOptions.filter(x => x.value !== 'clarifier')
    imageClassificationOptions = imageClassificationOptions.filter(x => x.value !== 'clarifier') 
  }

  const options =
    project_type === "object_detection"
      ? objectDetectionOptions
      : imageClassificationOptions;
      
  
  if (isNaN(Number(projectId))) {
    navigate("/projects");
  }

  const currentPage = useLocation()
    .pathname.split("/")[4]
    .replace(/-/g, " ")
    .replace(/\b\w/g, (match) => match.toUpperCase());

  const currentUrl = useLocation().pathname.split("/")[4];

  console.log("currenturl", currentUrl);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleRedirect = (pathname: string, parentItem: any) => {
    // if(project_type === 'instance_segmentation_-_(clarifier)' &&  pathname === 'data-labelling') return globalThis.window.location.href = `/segmentation?project_id=${projectId}`
    // The below if is for so we dont navigate to /test/test
    if (parentItem?.value === pathname) parentItem = false;
    const url = parentItem
      ? `/${project_type}/operations/${projectId}/${parentItem?.value}/${pathname}`
      : `/${project_type}/operations/${projectId}/${pathname}`;
    navigate(url);
    setMobileOpen(false);
  };

  const styles = useStyles();

  const MyNestedList = ({
    items,
    handleRedirect,
    level,
    isChildrenElement = false,
    parentItem,
  }: any) => {
    const [open, setOpen] = React.useState(0);

    const handleClick = (index: number) => {
      setOpen(index === open ? 0 : index);
    };
    return (
      <>
        {items.map((item: any, index: any) => (
          <React.Fragment key={item.value}>
            <ListItem
              disablePadding
              classes={{
                root: styles.root,
                selected: styles.selected,
              }}
              selected={!isChildrenElement && currentUrl === item.value}
            >
              <ListItemButton
                onClick={
                  item.children
                    ? () => handleClick(index)
                    : () => handleRedirect(item.value, parentItem)
                }
                style={{ paddingLeft: isChildrenElement && "72px" }}
              >
                {!isChildrenElement && (
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={item.name}
                  className="operations-menu-item"
                />
              </ListItemButton>
            </ListItem>
            {item.children && (
              <>
                <Collapse in={open === index} timeout="auto" unmountOnExit>
                  <Divider />
                  <MyNestedList
                    items={item.children}
                    handleRedirect={handleRedirect}
                    level={level + 1}
                    isChildrenElement={true}
                    parentItem={item}
                  />
                </Collapse>
              </>
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  const drawer = (
    <div>
      <Toolbar
        style={{
          paddingLeft: "0px",
          backgroundColor: "rgb(17 25 60)",
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <div style={{ padding: "10px 20px", width: drawerWidth }}>
          <img
            style={{ width: "-webkit-fill-available", cursor: "pointer" }}
            src="/avawatz-logo.svg"
            alt="Logo"
            onClick={() => navigate("/projects")}
          />
        </div>
      </Toolbar>
      <Divider />
      <div style={{ backgroundColor: "#12193C" }}>
        <h4
          style={{
            margin: "0px 0px 10px 20px",
            paddingTop: "10px",
            color: "white",
            textTransform: "capitalize",
          }}
        >
          {projectState.projectName || localStorage.getItem("projectName")}
        </h4>
        <Divider />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "93vh",
          maxHeight: "93vh",
        }}
      >
        <div>
          <h4 style={{ margin: "10px 0px 10px 20px" }}>Operations</h4>
          <Divider />
          <List disablePadding>
            <MyNestedList
              items={options}
              handleRedirect={handleRedirect}
              level={0}
            />
          </List>
        </div>
        <div>
          <h4 style={{ margin: "10px 0px 10px 20px" }}>Options</h4>
          <Divider />
          <List disablePadding>
            {/* <MyNestedList items={options} handleRedirect={handleRedirect} level={0} /> */}
            <ListItem
              disablePadding
              classes={{
                root: styles.root,
                selected: styles.selected,
              }}
              selected={currentUrl === "logs"}
            >
              <ListItemButton
                onClick={() => {
                  navigate(`/${project_type}/options/${projectId}/logs`);
                  setMobileOpen(false);
                }}
              >
                {
                  <ListItemIcon>
                    <MailIcon />
                  </ListItemIcon>
                }
                <ListItemText primary="Logs" className="tasks-menu-item" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${project_type}/options/${projectId}/upload-data`);
                  setMobileOpen(false);
                }}
              >
                {
                  <ListItemIcon>
                    <MailIcon />
                  </ListItemIcon>
                }
                <ListItemText
                  primary="Upload Data"
                  className="tasks-menu-item"
                />
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding>
              <ListItemButton>
                {
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                }
                <ListItemText
                  primary="Data Stats"
                  className="tasks-menu-item"
                />
              </ListItemButton>
            </ListItem> */}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${project_type}/options/${projectId}/labels`);
                  setMobileOpen(false);
                }}
              >
                {
                  <ListItemIcon>
                    <MailIcon />
                  </ListItemIcon>
                }
                <ListItemText primary="Labels" className="tasks-menu-item" />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          height: "75px",
          backgroundColor: "rgb(17 25 60)",
          boxShadow:
            "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Toolbar style={{ height: "inherit" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{ fontWeight: 900, color: "white", margin: "auto" }}
          >
            {currentPage}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          className="custom-sidebar"
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
